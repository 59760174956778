import axios from 'axios';
import {exceptionHandling} from './exceprtionHandling.js';

export const redirectCashierURL = 'transactions/payment' // All

//export const baseURL = 'https://nchbackend.apptimus.lk/api/v1/';// testing
//export const backURL = 'https://nchsystemsetup.apptimus.lk/' // testing
//export const backCashierURL = 'https://nchaccounts.apptimus.lk/' // testing
//export const backSystemSetupURL = 'https://nchbackend.apptimus.lk/api/v1/';// testing
//export const REPORT_URL="https://nchreports.apptimus.lk/" // NCH Live

//export const redirectCashierURL = 'transactions/payment' // All

//export const baseURL = 'https://nchbackend.apptimus.lk/api/v1/';// testing
//export const backURL = 'https://nchsystemsetup.apptimus.lk/' // testing
//export const backCashierURL = 'https://nchaccounts.apptimus.lk/' // testing
//export const backSystemSetupURL = 'https://localhost:9000/api/v1/';// testing


export const baseURL = 'https://backendapi.nchjaffna.lk/api/v1/' // NCH Local
export const backURL = 'https://systemsetup.nchjaffna.lk/' // NCH Local
export const backCashierURL = 'https://cashier.nchjaffna.lk/' //  NCH Local
export const backSystemSetupURL = 'https://systemsetup.nchjaffna.lk/api/' // NCH Local
export const REPORT_URL = 'https://reports.nchjaffna.lk/'

// export const baseURL = 'https://nchbackend.apptimed.com/api/v1/'; // live
// export const backURL = 'https://systemsetup.apptimed.com/' // live
// export const backCashierURL = 'https://accounts.apptimed.com/' // live




export const OPD_URL="http://localhost:5500/login"
export const INVENTORY_URL="http://139.59.31.223:5200/login"
export const CASHIER_URL="http://139.59.31.223:5300/login"
export const ACCOUNT_URL="http://139.59.31.223:5400/login"
export const HR_URL="http://139.59.31.223:5500/login"
export const IPD_URL="http://139.59.31.223:5600/login"

// export const baseURL = 'https://backendapi.nchjaffna.lk/api/v1/';// NCH Local
// export const backURL = 'https://systemsetup.nchjaffna.lk/' // NCH Local
// export const backCashierURL = 'https://cashier.nchjaffna.lk/' //  NCH Local
// export const backSystemSetupURL = 'https://systemsetup.nchjaffna.lk/api/' // NCH Local
// export const REPORT_URL="https://reports.nchjaffna.lk/" // NCH Live


// export const baseURL = 'https://nchbackend.apptimed.com/api/v1/'; // live 
// export const backURL = 'https://systemsetup.apptimed.com/' // live
// export const backCashierURL = 'https://accounts.apptimed.com/' // live




//export const OPD_URL="http://localhost:5500/login"
//export const INVENTORY_URL="http://139.59.31.223:5200/login"
//export const CASHIER_URL="http://139.59.31.223:5300/login"
//export const ACCOUNT_URL="http://139.59.31.223:5400/login"
//export const HR_URL="http://139.59.31.223:5500/login"
//export const IPD_URL="http://139.59.31.223:5600/login"

export const PATIENT_VIEW_URL=OPD_URL+"http://139.59.31.223:5600/login"

let getAccessToken = function () {
    try {        
        return "Bearer "+localStorage.getItem('token');
        // return "Bearer "+JSON.parse(localStorage.getItem('token'));
    } catch (e) {
        return "";
    }
}

const setURL = (uri,isSystemSetup = false) =>{
    let dId =  'did='+localStorage?.did
    let tId =  `tid=${localStorage?.tid ?? '7bbc5fa5-f6c2-4ae2-8abf-9fc36a547b81'}`
    let _url = "";

    _url = isSystemSetup ? backSystemSetupURL+uri : baseURL+uri
    
    _url += (_url.split('?')[1] ? '&':'?') + dId+'&'+tId;
    return _url;
}

const prepareConfig = {
    getheaders: {'Access-Control-Allow-Origin': '',"AllowedHeaders":"","AllowedOrigins":"*",
    'Access-Control-Allow-Headers':  "Origin, X-Requested-With, Content-Type, Accept, Authorization",  
    'Authorization':getAccessToken(),
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Headers': 'Accept-Ranges',
      
       },
    postheaders: {  'Access-Control-Allow-Origin': '*',
       'Access-Control-Allow-Headers':  "Origin, X-Requested-With, Content-Type, Accept, Authorization",  
       'Authorization':getAccessToken(),
       'Content-Type': 'application/json; charset=utf-8',
   }
//     postheaders: {  'Access-Control-Allow-Origin': '*',
//        'Access-Control-Allow-Headers':  "Origin, X-Requested-With, Content-Type, Accept, Authorization",  
//        'Authorization':getAccessToken(),
//        'Content-Type': 'multipart/form-data'
//    }

};

let apptimedRequest = {
    get:async function (uri,isSystemSetup = false,source,newSource) {
        // let cancelToken
        // if (typeof cancelToken != typeof undefined) {
        //     cancelToken.cancel("Operation canceled due to new request.")
        //   }
        
        //   //Save the cancel token for the current request
        //   cancelToken = axios.CancelToken.source()

        console.log("source",source);
        if (source) {
            source.cancel('Request canceled by the user');
        }
        
        return  new Promise((resolve,reject)=>{
        axios({
            method: 'get',
            url: setURL(uri,isSystemSetup),
            headers: prepareConfig.getheaders,
            cancelToken: newSource?.token
            
        })
        .then( response =>{
            
           resolve(response);
           console.log("🚀 ~ file: apptimedRequest.js ~ line 57 ~ returnnewPromise ~ response", response)
        })
        .catch(error=> {
            console.log("🚀 ~ file: apptimedRequest.js ~ line 60 ~ get returnnewPromise ~ error", error)
          
            if (axios.isCancel(error)) {
                // Handle request cancellation
                console.log('Request canceled:', error.message);
            } else {
                // Handle other errors
                console.error('Error:', error.message);
            }
           
            reject( exceptionHandling(error.response));
            // dispatch(failureFunction(error))
        })
    })
    }, 
  
    post: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'post',
                url: setURL(uri) ,
                data:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
               resolve(response);
            })
            .catch(error=> {
                reject( exceptionHandling(error.response));
                // dispatch(failureFunction(error))
            })
        })
    },
    patch: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'patch',
                url: setURL(uri),
                params:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
                resolve(response);
             })
             .catch(error=> {
                
                reject( exceptionHandling(error.response));
                 // dispatch(failureFunction(error))
             })
         })
    },
    put: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'put',
                url: setURL(uri) ,
                data:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
                resolve(response);
             })
             .catch(error=> {
                
                reject( exceptionHandling(error.response));
                 // dispatch(failureFunction(error))
             })
         })
    },
    delete: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'delete',
                url: setURL(uri) ,
                params:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
                resolve(response);
             })
             .catch(error=> {
                
                reject( exceptionHandling(error.response));
             })
         })
    }
};

export default apptimedRequest;
